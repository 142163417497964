import { AircraftClient, AircraftDTO, IConfig, FlightBaseDTO, FleetDTO, FleetClient } from "@/api/flightbooking";
import { Commit } from "vuex";

export class FleetWithAC extends FleetDTO {
  aircraft?: AircraftDTO[] | undefined;
}

export interface AircraftState {
  aircraft: Array<AircraftDTO>;
  flights: Array<FlightBaseDTO>;
  fleets: Array<FleetWithAC>;
}
// initial state
const state = () => ({
  aircraft: Array<AircraftDTO>(),
  flights: Array<FlightBaseDTO>(),
  fleets: Array<FleetWithAC>(),
});

// getters
const getters = {};

// actions
const actions = {
  getAircraft({ commit }: { commit: Commit }) {
    return new Promise<void>((resolve, reject) => {
      const client = new AircraftClient(new IConfig());
      client.getAllAircraft().then((aircraft) => {
        commit("setAircraft", aircraft);
        resolve();
      });
    });
  },
  getFlights({ commit }: { commit: Commit }, parameters: { aircraftId: string; startDate: string; endDate: string }) {
    return new Promise<void>((resolve, reject) => {
      const client = new AircraftClient(new IConfig());
      client.getFlightsByAircraft(parameters.aircraftId, parameters.startDate, parameters.endDate).then((flights) => {
        commit("setFlights", flights);
        resolve();
      });
    });
  },
  getFleets({ commit }: { commit: Commit }) {
    return new Promise<void>((resolve, reject) => {
      const client = new FleetClient(new IConfig());
      client.getAllFleets().then((fleets) => {
        commit("setFleets", fleets);
        resolve();
      });
    });
  },
};

// mutations
const mutations = {
  setAircraft(state: AircraftState, aircraft: Array<AircraftDTO>) {
    state.aircraft = aircraft;
  },
  setFlights(state: AircraftState, flights: Array<FlightBaseDTO>) {
    state.flights = flights;
  },
  setFleets(state: AircraftState, fleets: Array<FleetDTO>) {
    const c = fleets.map((f) => {
      const n = new FleetWithAC();
      n.id = f.id;
      n.name = f.name;
      n.registrations = f.registrations;
      n.aircraft = f.registrations.map((r) => state.aircraft.find((a) => a.registration == r));
      return n;
    });
    state.fleets = c;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
