<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        icon
        large
      >
        <v-avatar
          size="32px"
          item
        >{{ user.nickname }}
          <!-- <v-img
            src="https://cdn.vuetifyjs.com/images/logos/logo.svg"
            alt="Vuetify"
          ></v-img> -->
        </v-avatar>
      </v-btn>
    </template>
    <v-list rounded>
      <!-- <v-subheader>Profile</v-subheader> -->
      <v-list-item-group color="primary">
        <v-list-item :to="{ name: 'UserProfile' }">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-lock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

    </v-list>
  </v-menu>

</template>

<script>
import mainAuth from '@/services/auth'
export default {
  data: () => ({
    item: 1,
    items: [
      { text: 'Profile', icon: 'mdi-account', to: 'UserProfile' },
      { text: 'Logout', icon: 'mdi-flag', click: 'logout' }
    ]
  }),
  props: {
    user: null
  },
  methods: {
    logout: function() {
      mainAuth.signOut()
    }
  },
  computed: {
    isAuthenticated() {
      return mainAuth.isAuthenticated
    },
    profile() {
      return mainAuth.userProfile
    }
  }
}
</script>

<style></style>
