import Vue from "vue";
import Meta from "vue-meta";
import VueRouter, { RouteConfig } from "vue-router";
import mainAuth from "../services/auth";
import Home from "../views/Home.vue";
import store from "../store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Flight Booking",
      // metaTags: [
      //   {
      //     name: "description",
      //     content: "The home page of our example app.",
      //   },
      //   {
      //     property: "og:description",
      //     content: "The home page of our example app.",
      //   },
      // ],
    },
  },
  {
    path: "/bookings",
    name: "Bookings",
    meta: {
      authName: mainAuth.authName,
    },
    component: () => import(/* webpackChunkName: "Bookings" */ "../views/Bookings.vue"),
  },
  {
    path: "/aircraft",
    name: "Aircraft",
    meta: {
      authName: mainAuth.authName,
      title: "Flight Booking",
    },
    component: () => import(/* webpackChunkName: "Aircraft" */ "../views/Aircraft.vue"),
  },
  {
    path: "/aircraft/:aircraftId",
    name: "AircraftDetails",
    meta: {
      authName: mainAuth.authName,
      title: "Aircraft",
    },
    component: () => import(/* webpackChunkName: "AircraftDetails" */ "../views/AircraftDetails.vue"),
  },
  {
    path: "/stations",
    name: "StationsList",
    meta: {
      authName: mainAuth.authName,
      title: "Flight Booking",
    },
    component: () => import(/* webpackChunkName: "Aircraft" */ "../views/StationsList.vue"),
  },
  {
    path: "/station/:icaoCode",
    name: "Station",
    meta: {
      authName: mainAuth.authName,
      title: "Flight Booking",
    },
    component: () => import(/* webpackChunkName: "AircraftDetails" */ "../views/Station.vue"),
  },
  {
    path: "/flight/:flightId",
    name: "Flight",
    meta: {
      authName: mainAuth.authName,
      title: "Flight",
    },
    component: () => import(/* webpackChunkName: "Flight" */ "../views/Flight.vue"),
  },
  {
    path: "/user/profile",
    name: "UserProfile",
    meta: {
      authName: mainAuth.authName,
      title: "User profile",
    },
    component: () => import(/* webpackChunkName: "Flight" */ "../components/Profile/BasicProfile.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

Vue.use(Meta);
mainAuth.useRouter(router);
export default router;
