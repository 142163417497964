import { OrganizationClient, OrganizationDTO, IConfig } from "@/api/flightbooking";
import { Commit } from "vuex";

export interface OrganizationState {
  all: Array<OrganizationDTO>;
  selected: string;
  loading: boolean;
}
// initial state
const state = () => ({
  all: Array<OrganizationDTO>(),
  selected: localStorage.getItem("organizationId"),
  loading: true,
});

// getters
const getters = {};

// actions
const actions = {
  getAllOrganizations({ commit }: { commit: Commit }) {
    return new Promise<void>((resolve, reject) => {
      const client = new OrganizationClient(new IConfig());
      client.getOrganizations().then(orgs => {
        commit("setOrganizations", orgs);
        resolve();
      });
    });
  },
};

// mutations
const mutations = {
  setOrganizations(state: OrganizationState, orgs: Array<OrganizationDTO>) {
    state.all = orgs;
    state.loading = false;
  },
  setCurrentOrganization(state: OrganizationState, newOrg: string) {
    localStorage.setItem("organizationId", newOrg);
    state.selected = newOrg;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
