





















































export default {
  data: () => ({
    rowsPerAircraft: 3,
    firstAircraftRow: 2,
    aircraft: [
      {
        id: 'OYBSV',
        type: 'C172',
        plans: [
          {
            start: new Date(Date.parse('2020-10-02 09:00Z')),
            end: new Date(Date.parse('2020-10-20 10:00Z')),
            origin: 'EKRK',
            destination: 'EKYT'
          },
          {
            start: new Date(Date.parse('2020-10-02 14:00Z')),
            end: new Date(Date.parse('2020-10-20 17:00Z')),
            origin: 'EKYT',
            destination: 'EKRK'
          }
        ],
        bookings: [
          {
            start: new Date(Date.parse('2020-10-02 08:00Z')),
            end: new Date(Date.parse('2020-10-20 11:00Z'))
          },
          {
            start: new Date(Date.parse('2020-10-02 13:00Z')),
            end: new Date(Date.parse('2020-10-20 19:00Z'))
          }
        ]
      },
      {
        id: 'OYABC',
        type: 'C172',
        plans: [
          {
            start: new Date(Date.parse('2020-10-02 10:00Z')),
            end: new Date(Date.parse('2020-10-20 12:00Z')),
            origin: 'EKRK',
            destination: 'EKYT'
          },
          {
            start: new Date(Date.parse('2020-10-02 13:00Z')),
            end: new Date(Date.parse('2020-10-20 16:00Z')),
            origin: 'EKYT',
            destination: 'EKRK'
          }
        ],
        bookings: [
          {
            start: new Date(Date.parse('2020-10-02 10:00Z')),
            end: new Date(Date.parse('2020-10-20 16:00Z'))
          },
          {
            start: new Date(Date.parse('2020-10-02 18:00Z')),
            end: new Date(Date.parse('2020-10-20 20:00Z'))
          }
        ]
      }
    ]
  }),
  methods: {
    getColumn(d: Date): number {
      return d.getUTCHours() + 1
    },
    getRow(index: number, offset = 0): number {
      return this.firstAircraftRow + offset + index * this.rowsPerAircraft
    }
  }
}
