import { UserDTO, IConfig, UserClient } from "@/api/flightbooking";
import { Commit, Dispatch } from "vuex";
const state = {
  token: localStorage.getItem("auth-token") || "",
  status: "",
  currentUser: UserDTO,
};

const getters = {
  isAuthenticated: (authState: any) => !!authState.token,
  authStatus: (authState: any) => authState.status,
  authToken: (authState: any) => authState.token,
};

const actions = {
  getCurrentUser({ commit }: { commit: Commit }) {
    return new Promise<void>((resolve, reject) => {
      const client = new UserClient(new IConfig());
      client.getCurrentUser().then(user => {
        commit("setCurrentUser", user);
        resolve();
      });
    });
  },
  authLogout: ({ commit, dispatch }: { commit: any; dispatch: any }) => {
    return new Promise<void>((resolve, reject) => {
      commit("authLogout");
      localStorage.removeItem("auth-token");
      resolve();
    });
  },
};

const mutations = {
  authRequest: (authState: any) => {
    authState.status = "attempting authentication request";
  },
  authSuccess: (authState: any, authToken: string) => {
    authState.status = "authentication succeeded";
    authState.token = authToken;
  },
  authError: (authState: any) => {
    authState.status = "error";
  },
  authLogout: (authState: any) => {
    authState.token = "";
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
