



































































































































































//import OrganizationSelector from "./OrganizationSelector.vue";
import Avatar from './Avatar.vue'
import SimpleIndicator from '../DataState/SimpleIndicator.vue'
import { mapState, mapMutations } from 'vuex'
import { StatusClient, DataStatusDTO, IConfig } from '@/api/flightbooking'
import mainAuth from '@/services/auth'
export default {
  data: () => ({
    dialog: false,
    drawer: null,
    items: [
      { icon: 'mdi-home', text: 'Home', to: '/', chip: 'INOP' },
      { icon: 'mdi-airplane', text: 'Aircraft', to: '/aircraft' },

      { icon: 'mdi-airport', text: 'Stations', to: '/stations' },
      {
        icon: 'mdi-calendar',
        text: 'Bookings',
        to: '/bookings'
      }
      //{ icon: "mdi-airplane-takeoff", text: "Upcoming" },
      // {
      //   icon: "mdi-chevron-up",
      //   "icon-alt": "mdi-chevron-down",
      //   text: "Labels",
      //   model: true,
      //   children: [{ icon: "mdi-plus", text: "Create label" }],
      // },
      // {
      //   icon: "mdi-chevron-up",
      //   "icon-alt": "mdi-chevron-down",
      //   text: "More",
      //   model: false,
      //   children: [
      //     { text: "Import" },
      //     { text: "Export" },
      //     { text: "Print" },
      //     { text: "Undo changes" },
      //     { text: "Other contacts" },
      //   ],
      // },
      // { icon: "mdi-cog", text: "Settings" },
      // { icon: "mdi-message", text: "Send feedback" },
      // { icon: "mdi-help-circle", text: "Help" },
      // { icon: "mdi-cellphone-link", text: "App downloads" },
      // { icon: "mdi-keyboard", text: "Go to the old version" },
    ],
    status: {} as DataStatusDTO
  }),
  computed: {
    ...mapState('users', ['currentUser'])
  },
  methods: {
    loadData: function() {
      const c = new StatusClient(new IConfig())
      c.getDataAges().then(r => {
        this.status = r
      })
    }
  },
  components: {
    //  OrganizationSelector,
    SimpleIndicator,
    Avatar
  },
  created() {
    if (mainAuth.isAuthenticated && !(typeof this.currentUser === 'object')) {
      this.$store.dispatch('users/getCurrentUser')
    }
    this.loadData()

    setInterval(
      function() {
        this.loadData()
      }.bind(this),
      60000
    )
  }
}
