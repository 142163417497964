import { FlightClient, FlightDTO, FlightPositionDTO, IConfig } from "@/api/flightbooking";
import { Commit } from "vuex";

export interface FligthState {
  flight: FlightDTO;
  positions: Array<FlightPositionDTO>;
  rawData: String;
  rawPlan: String;
}
// initial state
const state = () => ({
  flight: FlightDTO,
  positions: Array<FlightPositionDTO>(),
  rawData: "",
  rawPlan: "",
});

// getters
const getters = {};

// actions
const actions = {
  getFlight({ commit }: { commit: Commit }, flightId: string) {
    return new Promise<void>((resolve, reject) => {
      commit("setFlight", null);
      const client = new FlightClient(new IConfig());
      client.getFlight(flightId).then((flight) => {
        commit("setFlight", flight);
        resolve();
      });
    });
  },
  getPositions({ commit }: { commit: Commit }, flightId: string) {
    return new Promise<void>((resolve, reject) => {
      commit("setPositions", []);
      const client = new FlightClient(new IConfig());
      client.getFlightPositions(flightId).then((pos) => {
        pos = pos.sort((a, b) => {
          return a.timeOver.getTime() - b.timeOver.getTime();
        });
        commit("setPositions", pos);
        resolve();
      });
    });
  },
  getRaw({ commit }: { commit: Commit }, flightId: string) {
    return new Promise<void>((resolve, reject) => {
      commit("setRawData", "");
      commit("setRawPlan", "");
      const client = new FlightClient(new IConfig());
      client.getRawData(flightId).then((data) => {
        commit("setRawData", data);
        resolve();
      });
      client.getRawPlan(flightId).then((data) => {
        commit("setRawPlan", data);
        resolve();
      });
    });
  },
};
import PrettyData from "pretty-data";
const pd = PrettyData.pd;
// mutations
const mutations = {
  setFlight(state: FligthState, flight: FlightDTO) {
    state.flight = flight;
  },
  setPositions(state: FligthState, positions: Array<FlightPositionDTO>) {
    state.positions = positions;
  },
  setRawData(state: FligthState, data: String) {
    state.rawData = pd.xml(data);
  },
  setRawPlan(state: FligthState, data: String) {
    state.rawPlan = pd.xml(data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
