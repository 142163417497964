<template>
  <v-card>
    <v-card-title v-if="!noSearch">
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="aircraft"
      :items-per-page="15"
      :search="search"
      @click:row="handleClick"
      show-group-by
      dense
      class="elevation-2"
    >
      <template v-slot:[`item.isMonitored`]="{ item }">
        <v-chip
          v-if="item.isMonitored"
          color="green"
        >
          <v-icon>mdi-check</v-icon>
        </v-chip>
        <v-chip
          v-else
          color="red"
        >
          <v-icon>mdi-close</v-icon>
        </v-chip>
      </template>
      <!-- <template v-slot:item.isLive="{ item }">
      <v-chip
        v-if="item.isLive"
        color="green"
      >
        <v-icon>mdi-airplane</v-icon>
      </v-chip>
    </template> -->
    </v-data-table>
  </v-card>
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import { AircraftDTO } from '@/api/flightbooking'
import { mapState, mapMutations } from 'vuex'
export default {
  props: {
    aircraft: Object,
    noSearch: Boolean
  },
  data: () => ({
    search: '',
    headers: [
      {
        text: 'Aircraft',
        align: 'start',
        sortable: false,
        value: 'registration',
        groupable: false
      },
      { text: 'Type', value: 'type' },
      { text: 'Monitored', value: 'isMonitored' }
      // { text: "Live", value: "isLive" },
    ]
  }),
  methods: {
    handleClick: function(row) {
      this.$router.push({
        name: 'AircraftDetails',
        params: { aircraftId: row.registration }
      })
    }
  }
}
</script>

<style></style>
