import { StationClient, AirportDTO, IConfig, FlightBaseDTO } from "@/api/flightbooking";
import { Commit } from "vuex";

export interface StationState {
  stations: Array<AirportDTO>;
  flights: Array<FlightBaseDTO>;
  metar: String;
  taf: String;
}
// initial state
const state = () => ({
  stations: Array<AirportDTO>(),
  flights: Array<FlightBaseDTO>(),
  metar: String,
  taf: String,
});

// getters
const getters = {};

// actions
const actions = {
  getStations({ commit }: { commit: Commit }) {
    return new Promise<void>((resolve, reject) => {
      const client = new StationClient(new IConfig());
      client.getMonitoredStations().then((stations) => {
        commit("setStations", stations);

        resolve();
      });
    });
  },
  getFlights({ commit }: { commit: Commit }, parameters: { icaoCode: string; startDate: string; endDate: string }) {
    return new Promise<void>((resolve, reject) => {
      const client = new StationClient(new IConfig());
      client.getFlightsByAirport(parameters.icaoCode, parameters.startDate, parameters.endDate).then((flights) => {
        commit("setFlights", flights);
        resolve();
      });
      client.getMetar(parameters.icaoCode).then((str) => {
        commit("setMetar", str);
        resolve();
      });
      client.getTaf(parameters.icaoCode).then((str) => {
        commit("setTaf", str);
        resolve();
      });
    });
  },
};

// mutations
const mutations = {
  setStations(state: StationState, stations: Array<AirportDTO>) {
    state.stations = stations;
  },
  setFlights(state: StationState, flights: Array<FlightBaseDTO>) {
    state.flights = flights;
  },
  setMetar(state: StationState, newState: String) {
    state.metar = newState;
  },
  setTaf(state: StationState, newState: String) {
    state.taf = newState;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
