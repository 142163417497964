



















import Vue from 'vue'
import NavBar from './components/Navigation/NavBar.vue'

export default Vue.extend({
  name: 'App',

  components: {
    NavBar
  }
})
