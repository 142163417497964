import Vue from "vue";
import { createOidcAuth, SignInType, LogLevel } from "vue-oidc-client";

const loco = window.location;
const appRootUrl = `${loco.protocol}//${loco.host}${process.env.BASE_URL}`;

const mainOidc = createOidcAuth(
  "squawkIdent",
  SignInType.Popup, //SignInType.Window
  appRootUrl,
  {
    authority: process.env.VUE_APP_AUTH,
    client_id: "interactive", // 'implicit.shortlived',
    client_secret: "49C1A7E1-0C79-4A89-A3D6-A37998FB86B0",
    response_type: "code",
    scope: "openid profile flightbooking", // email",
    // test use
    //prompt: "login",
    //login_hint: "bob",
  },
  console,
  LogLevel.Debug
);
Vue.prototype.$oidc = mainOidc;
export default mainOidc;
