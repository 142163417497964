import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mainAuth from "./services/auth";
import vuetify from "./plugins/vuetify";
import * as VueGoogleMaps from "gmap-vue";

Vue.config.productionTip = false;
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyD51Ka5zSsLOjmEf44ZiS-8NNXdA90pT9Q",
  },
});
mainAuth.startup().then((ok) => {
  if (ok) {
    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  }
});

Date.prototype.toNiceUtc = function(): string {
  const offset = this.getTimezoneOffset();
  const d = new Date(this.getTime() + offset * 60 * 1000);
  return (
    [
      d.getFullYear(),
      (d.getMonth() + 1).toString().padStart(2, "0"),
      d
        .getDate()
        .toString()
        .padStart(2, "0"),
    ].join("-") +
    " " +
    [
      d
        .getHours()
        .toString()
        .padStart(2, "0"),
      d
        .getMinutes()
        .toString()
        .padStart(2, "0"),
      // d
      //   .getSeconds()
      //   .toString()
      //   .padStart(2, "0"),
    ].join(":") +
    "Z"
  );
};
