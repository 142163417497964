import Vue from "vue";
import Vuex from "vuex";
import organizations from "./modules/organizations";
import users from "./modules/users";
import aircraft from "./modules/aircraft";
import flights from "./modules/flights";
import bookings from "./modules/bookings";
import stations from "./modules/stations";
import auth from "./modules/auth";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    organizations,
    users,
    aircraft,
    bookings,
    flights,
    stations,
  },
});
