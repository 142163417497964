import { BookingClient, BookingDTO, IConfig } from "@/api/flightbooking";
import { Commit, Dispatch } from "vuex";

export interface BookingsState {
  bookings: Array<BookingDTO>;
}
// initial state
const state = () => ({
  bookings: Array<BookingDTO>(),
});

// getters
const getters = {};

// actions
const actions = {
  getBookings({ commit }: { commit: Commit }) {
    return new Promise<void>((resolve, reject) => {
      const client = new BookingClient(new IConfig());
      client
        .getMyBookings()
        .then((bookings) => {
          commit("setBookings", bookings);
          resolve();
        })
        .catch((e) => {
          console.log(e);
          reject();
        });
    });
  },
  saveBooking({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, editedBooking: BookingDTO) {
    return new Promise<void>((resolve, reject) => {
      const client = new BookingClient(new IConfig());
      client
        .createBooking(editedBooking)
        .then((r) => {
          dispatch("getBookings");
          resolve(r);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
  setBookings(state: BookingsState, bookings: Array<BookingDTO>) {
    state.bookings = bookings;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
