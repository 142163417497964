import { UserClient, UsersClient, UserDTO, IConfig } from "@/api/flightbooking";
import { Commit } from "vuex";

export interface UserState {
  currentUser: UserDTO;
  users: Array<UserDTO>;
}
// initial state
const state = () => ({
  currentUser: UserDTO,
  users: Array<UserDTO>(),
});

// getters
const getters = {
  currentUser: function(state: UserState) {
    return state.currentUser;
  },
};

// actions
const actions = {
  getCurrentUser({ commit }: { commit: Commit }) {
    return new Promise<void>((resolve, reject) => {
      const client = new UserClient(new IConfig());
      client.getCurrentUser().then((user) => {
        commit("setCurrentUser", user);
        resolve();
      });
    });
  },
  getUsers({ commit }: { commit: Commit }) {
    return new Promise<void>((resolve, reject) => {
      const client = new UsersClient(new IConfig());
      client.getAllUsers().then((users) => {
        commit("setUsers", users);
        resolve();
      });
    });
  },
  saveUser({ commit }: { commit: Commit }, editedUser: UserDTO) {
    return new Promise<void>((resolve, reject) => {
      const client = new UserClient(new IConfig());
      client.saveUser(editedUser).then((r) => {
        this.$store.dispatch("users/getCurrentUser");
        resolve();
      });
    });
  },
};

// mutations
const mutations = {
  setCurrentUser(state: UserState, user: UserDTO) {
    state.currentUser = user;
  },
  setUsers(state: UserState, users: UserDTO[]) {
    state.users = users;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
