<template>
  <v-container v-if="$oidc.isAuthenticated">
    <v-card class="pa-3">
      <v-card-title>OPS</v-card-title>
      <gantt-chart />
      <ops-overview />
    </v-card>
    <v-card class="pa-3">
      <v-card-title>Aircraft</v-card-title>
      <aircraft-table />
    </v-card>
    <v-card>
      <v-card-title>My Bookings</v-card-title>
      <bookings-calendar />
    </v-card>
  </v-container>
  <v-container v-else>
    <v-btn
      @click="tryLogin"
      large
    >Login</v-btn>
  </v-container>
</template>

<script>
import AircraftTable from './../components/AircraftTable'
import BookingsCalendar from './../components/Booking/BookingsCalendar'
import OpsOverview from './../components/Ops/OpsOverview'

import mainAuth from '../services/auth'
import GanttChart from '@/components/Ops/GanttChart.vue'
export default {
  name: 'Home',
  components: {
    AircraftTable,
    BookingsCalendar,
    OpsOverview,
    GanttChart,
  },
  data: () => ({
    isAuthenticated: mainAuth.isAuthenticated,
  }),
  mounted: function () {
    const cu = this.$store.getters['users/currentUser']
    if (cu.nickname == null) {
      if (mainAuth.isAuthenticated) {
        this.$store.dispatch('users/getCurrentUser')
      }
    } else {
      console.log(this.$store.getters['users/currentUser'])
    }
  },
  methods: {
    tryLogin: function () {
      mainAuth.signIn().then((user) => {
        console.log(user)
        this.$store.dispatch('users/getCurrentUser')
      })
    },
  },
}
</script>
