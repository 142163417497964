<template>
  <g-chart
    ref="chart"
    :settings="{ packages: ['timeline'] }"
    type="Timeline"
    :data="chartData"
    :options="chartOptions"
    :events="chartEvents"
  />
</template>
<script>
import { GChart } from 'vue-google-charts'
export default {
  components: {
    GChart,
  },
  data() {
    return {
      chartEvents: {
        select: () => {
          const selectedIndex = this.$refs.chart.chartObject.getSelection()[0]
          console.log(selectedIndex)
          console.log(this.chartData[selectedIndex.row + 1])
        },
      },
      // Array will be automatically processed with visualization.arrayToDataTable function
      chartData: [
        ['Reg.', 'Name', 'Start', 'End'],
        [
          'OYBSV',
          'Michael Bojsen',
          new Date(2020, 12, 1, 9, 0),
          new Date(2020, 12, 1, 12, 0),
        ],
        [
          'OYBSV',
          'EKRK - EKYT',
          new Date(2020, 12, 1, 9, 30),
          new Date(2020, 12, 1, 10, 30),
        ],
        [
          'OYBSV',
          'EKYT - EKRK',
          new Date(2020, 12, 1, 10, 30),
          new Date(2020, 12, 1, 11, 30),
        ],
        [
          'OYBSV',
          'Michael Bojsen',
          new Date(2020, 12, 1, 13, 0),
          new Date(2020, 12, 1, 15, 0),
        ],
        [
          'OYBSV',
          'EKRK - EKYT',
          new Date(2020, 12, 1, 13, 30),
          new Date(2020, 12, 1, 14, 30),
        ],
        [
          'OYLEL',
          'Plan',
          new Date(2020, 12, 1, 10, 0),
          new Date(2020, 12, 1, 20, 0),
        ],
        ['PEL', 'Plan', new Date(2020, 12, 1, 7), new Date(2020, 12, 1, 9)],
      ],
      chartOptions: {
        hAxis: {
          format: 'HH:mm',
          minValue: new Date(2020, 12, 1),
          maxValue: new Date(2020, 12, 2),
        },
        gantt: {
          height: 275,
          palette: [
            {
              color: this.$vuetify.theme.themes['light'].secondary,
              dark: this.$vuetify.theme.themes['light'].primary,
              light: this.$vuetify.theme.themes['light'].primary,
            },
          ],
        },
      },
    }
  },
  methods: {
    daysToMilliseconds: (days) => {
      return days * 24 * 60 * 60 * 1000
    },
  },
}
</script>