<template>
  <v-avatar
    :color="color"
    :size="size"
    :title="name"
  >
    <span class="white--text">{{value}}</span>
  </v-avatar>
</template>

<script>
export default {
  props: {
    size: Number,
    value: Number,
    name: String,
  },
  computed: {
    color: function() {
      if (this.value < 60) return "green";
      if (this.value < 200) return "yellow";
      return "red";
    },
  },
};
</script>

<style>
</style>